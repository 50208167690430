<template>
  <div class="dashboardProjects">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <DataTable
      class="p-datatable-sm "
      :value="appointments"
      :loading="isLoading"
      :rowHover="true"
      editMode="cell"
      sortMode="multiple"
      :multiSortMeta="multiSortMeta"
      :rowClass="rowClass"
      filterDisplay="row"
      :stateKey="tableState.filterName"
      stateStorage="local"
      :rows.sync="tableState.pagination.rowsPerPage"
      dataKey="id"
      :paginator="true"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 100, 200]"
      :filters.sync="tableState.filters"
      @cell-edit-complete="onCellEditComplete"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <div>
              <label for="fromDate"></label>
              <Calendar
                ref="appointmentCalendar"
                v-model="defaultCustomFilters.from.value"
                dateFormat="dd.mm.yy"
                :placeholder="$t('from')"
                :showWeek="true"
                :yearNavigator="true"
                :monthNavigator="true"
                v-on:date-select="onFromDateSelect"
              />
            </div>
            <div>
              <label for="toDate"></label>
              <Calendar
                ref="appointmentCalendar"
                v-model="defaultCustomFilters.to.value"
                dateFormat="dd.mm.yy"
                :placeholder="$t('to')"
                :showWeek="true"
                :yearNavigator="true"
                :monthNavigator="true"
                v-on:date-select="onToDateSelect"
              />
            </div>
            <button class="btn btn-xs btn-inverse" @click.prevent="clearDate">
              <i class="fa fa-times" />
            </button>
          </div>

          <div class="table-header-right">
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
            <Button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="expandAll">
              Alle öffnen
            </Button>
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="collapseAll">
              Alle schliessen
            </button>

            <div class="button-right">
              <button class="btn btn-success mb-2 right" @click="onCreateAppointment">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('new') }}
              </button>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <template #groupheader="slotProps">
        <strong>{{ slotProps.data.weekday }}</strong>
      </template>

      <Column
        field="appointmentType"
        :header="$t('appointmentType')"
        :styles="{ width: '7%' }"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <b-badge :variant="getAppointmentTypeColor(slotProps.data.appointmentType)">
            {{ $t(slotProps.data.appointmentType) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('AppointmentType')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.appointmentTypes"
            :options="appointmentTypes"
            @input="onAppointmentTypeFilter"
            optionLabel="label"
            :placeholder="$t('appointmentType')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>

      <Column
        field="appointmentState"
        :header="$t('appointmentState')"
        :showFilterMenu="false"
        :styles="{ width: '7%' }"
      >
        <template #body="slotProps">
          <b-badge :variant="getAppointmentStateColor(slotProps.data.appointmentState)">
            {{ $t(slotProps.data.appointmentState) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('AppointmentState')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getAppointmentStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getAppointmentStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.appointmentStates"
            :options="appointmentStates"
            @input="onAppointmentStateFilter"
            optionLabel="label"
            :placeholder="$t('appointmentState')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>

      <Column
        field="appointmentApprovedState"
        :header="$t('appointmentApprovedState_short')"
        :styles="{ width: '7%' }"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <b-badge :variant="getYesNoStateColor(slotProps.data.appointmentApprovedState)">
            {{ $t(slotProps.data.appointmentApprovedState) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown v-model="data[field]" :options="yesNo()" :placeholder="$t('select')">
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <Dropdown
            :value="tableState.filters.appointmentApprovedState.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="onAppointmentApprovedStateFilter"
            :style="{ 'max-width': '100px' }"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="installationStartAt"
        :header="$t('start')"
        :styles="{ width: '8%' }"
        headerClass="center"
        className="center"
      >
        <template #body="slotProps">
          {{ $datetime(slotProps.data[slotProps.column.field]) }}
        </template>
        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>
      </Column>
      <Column
        field="installationEndAt"
        :header="$t('end')"
        :styles="{ width: '8%' }"
        headerClass="center"
        className="center"
      >
        <template #body="slotProps">
          {{ $datetime(slotProps.data[slotProps.column.field]) }}
        </template>
        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>
      </Column>

      <Column
        field="project.number"
        header="#"
        :styles="{ width: '7%' }"
        :headerStyle="{ width: '60px' }"
        headerClass="center"
        className="center"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.project && slotProps.data.project.number"
            target="_blank"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: slotProps.data.project.number },
            }"
            >{{ slotProps.data.project.number.toString().padStart(4, '0') }}
            <i class="fa fa-sm fa-external-link"></i>
          </router-link>
        </template>
      </Column>

      <Column field="project" :header="$t('customer')">
        <template #body="slotProps">
          <multiselect
            class="multiselect-project"
            :placeholder="$t('filter_by_lastname')"
            v-model="slotProps.data.project"
            :options="filteredProjects"
            :allow-empty="false"
            track-by="number"
            :deselectLabel="$t('deselectLabel')"
            selectLabel=""
            selectedLabel=""
            :internal-search="false"
            :show-labels="true"
            v-on:search-change="onProjectSearch"
            v-on:select="onSelectProject(slotProps)"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <div>{{ option.customer.lastname }}, {{ option.customer.firstname }},</div>
              <div>
                {{ option.customer.street }} {{ option.customer.streetNumber }},
                {{ option.customer.zip }}
                {{ option.customer.city }}
              </div>
            </template>
            <template slot="option" slot-scope="{ option }">
              <div>{{ option.customer.lastname }}, {{ option.customer.firstname }},</div>
              <div>
                {{ option.customer.street }} {{ option.customer.streetNumber }},
                {{ option.customer.zip }}
                {{ option.customer.city }}
              </div>
            </template>
          </multiselect>
        </template>
      </Column>

      <Column
        field="project.customer.zip"
        :header="$t('zip')"
        :styles="{ width: '7%' }"
        headerClass="center"
        className="center"
      >
      </Column>

      <Column
        field="project.solarPlant.solarPanelCount"
        :header="$t('solarPanelCount_short')"
        headerClass="center"
        className="center"
        :styles="{ 'min-width': '80px' }"
      >
      </Column>

      <Column
        field="project.employer.name"
        :header="$t('employer')"
        headerClass="center"
        className="center"
      >
      </Column>

      <Column field="employees" :header="$t('employee')" v-if="isAdmin">
        <template #body="slotProps">
          <multiselect
            class="multiselect-employees"
            v-model="slotProps.data.employees"
            :options="filteredEmployees"
            track-by="number"
            v-on:search-change="onFilterEmployees"
            v-on:select="onSelectEmployee(slotProps)"
            v-on:remove="onSelectEmployee(slotProps)"
            :filter="true"
            :internal-search="false"
            :close-on-select="false"
            :clear-on-select="false"
            :multiple="true"
            :show-labels="false"
            :allow-empty="true"
            :deselectLabel="$t('deselectLabel')"
            selectLabel=""
            selectedLabel=""
            :placeholder="'Mitarbeiter ' + $t('select') + '/suchen'"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.firstname }} {{ option.lastname }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ option.firstname }} {{ option.lastname }}
            </template>
            <template slot="tag" slot-scope="{ option }">
              <div>{{ option.firstname }} {{ option.lastname }}</div>
            </template>
            <template slot="noResult">
              <div>{{ $t('no_results') }}</div>
            </template>
          </multiselect>
        </template>
      </Column>

      <Column field="description" :header="$t('notes')">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
      </Column>

      <Column :styles="{ 'min-width': '110px' }">
        <template #body="slotProps">
          <ConfirmButton
            colorVariant="white"
            :callbackId="slotProps.data"
            :confirmCallback="onDuplicateAppointment"
            :preventHide="monthChangeClicked"
            :hideCallback="resetPreventHide"
            class="btn-icon"
          >
            <template v-slot:buttonText>
              <i class="link-icon fa fa-lg fa-copy mr-2"></i>
            </template>
            <template v-slot:header>
              {{ $t('duplicate_appointment') }}: Datum und Uhrzeit wählen
            </template>
            <template v-slot:confirmButtonText>
              {{ $t('duplicate_appointment') }}
            </template>
            <template v-slot:content>
              <Calendar
                :inline="true"
                v-model="copyDate"
                :showTime="true"
                :stepMinute="15"
                :styles="{ width: '100%' }"
                @month-change="onCalendarMonthChange"
                @year-change="onCalendarMonthChange"
              >
              </Calendar>
            </template>
          </ConfirmButton>

          <ConfirmButton
            colorVariant="white"
            :disabled="emailButtonDisabled(slotProps.data)"
            :callbackId="slotProps.data"
            :confirmCallback="onSendAppointmentConfirmationMail"
            class="btn-icon"
          >
            <template v-slot:buttonText>
              <i class="link-icon fa fa-lg fa-envelope mr-2"></i>
            </template>
            <template v-slot:header> {{ $t('confirm_appointment') }}? </template>
            <template v-slot:confirmButtonText>
              {{ $t('confirm_appointment') }}
            </template>
            <template v-slot:content>
              E-Mail-Bestätigung wird versendet an:
              <strong v-if="slotProps.data.project && slotProps.data.project.customer.email">
                {{ slotProps.data.project.customer.email }}</strong
              >
            </template>
          </ConfirmButton>
          <ConfirmButton
            colorVariant="white"
            :callbackId="slotProps.data.number"
            :confirmCallback="onDeleteAppointment"
            class="btn-icon"
          >
            <template v-slot:buttonText>
              <i class="link-icon fa fa-lg fa-times mr-2"></i>
            </template>
            <template v-slot:header> {{ $t('delete_appointment') }}? </template>
            <template v-slot:confirmButtonText>
              {{ $t('delete_appointment') }}
            </template>
          </ConfirmButton>
        </template>
      </Column>
      <template #footer>
        {{ getAppointments ? getAppointments.length : 0 }} Termine
        <span v-if="calendarWeek[0]">
          im Zeitraum
          {{ calendarWeek[0] ? $date(calendarWeek[0]) : 'N/A' }} bis
          {{ calendarWeek[0] ? $date(calendarWeek[1]) : 'N/A' }}
        </span>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import ConfirmButton from '@/components/ConfirmButton';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Multiselect from 'vue-multiselect';
import MultiSelect from 'primevue/multiselect';
import {
  getAppointmentTypeColor,
  getAppointmentStateColor,
  getYesNoStateColor,
} from '../../helpers/colors';
import { yesNo } from '@/helpers/enums';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'DashboardProjects',
  components: {
    Breadcrumbs,
    DataTable,
    Calendar,
    Column,
    InputText,
    Dropdown,
    Multiselect,
    ConfirmButton,
    MultiSelect,
  },
  data() {
    return {
      monthChangeClicked: false,
      copyDate: this.$dayjs()
        .startOf('day')
        .add(8, 'hour')
        .toDate(),
      calendarWeek: [],
      filteredEmployees: [],
      expandedRowGroups: null,
      loading: false,
      multiSortMeta: [{ field: 'installationStartAt', order: 1 }],

      /** filters for project multiselect */
      filteredProjects: [],
      projectsFilter: {
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        sortField: 'number',
        sortOrder: -1,
        filterName: 'appointments-project-filters',
        filters: {
          number: { value: null, matchMode: FilterMatchMode.EQUALS },
          customerLastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
          customerFirstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
      },

      /** persistent table filters  */
      defaultFilters: {
        appointmentApprovedState: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      defaultCustomFilters: {
        from: {
          value: this.$dayjs()
            .startOf('week')
            .toDate(),
          matchMode: FilterMatchMode.EQUALS,
        },
        to: {
          value: this.$dayjs()
            .startOf('week')
            .add(6, 'days')
            .toDate(),
          matchMode: FilterMatchMode.EQUALS,
        },
        appointmentStates: [],
        appointmentTypes: [],
      },

      tableState: {
        filterName: 'dashboardProjects-table-filters',
        customFilterName: 'dashboardProjects-custom-table-filters',
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
        pagination: {
          rowsPerPage: 25,
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['isLoading', 'getAppointments', 'getEnumValues', 'getProjects', 'getEmployees']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('PROJECTS_DASHBOARD') }];
    },
    appointmentStates() {
      const appointmentStates = this.getEnumValues('AppointmentState').map((appointmentState) => {
        return { value: appointmentState, label: this.$t(appointmentState) };
      });
      return appointmentStates;
    },
    appointmentTypes() {
      const appointmentTypes = this.getEnumValues('AppointmentType').map((appointmentType) => {
        return { value: appointmentType, label: this.$t(appointmentType) };
      });
      return appointmentTypes;
    },
    appointments() {
      return this.getAppointments;
    },
  },
  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchAppointments',
      'createAppointment',
      'duplicateAppointment',
      'updateAppointment',
      'initAppointment',
      'fetchProjects',
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'fetchEmployees',
      'deleteAppointment',
      'sendAppointmentMail',
    ]),
    applyCustomDateRange() {
      if (this.defaultCustomFilters.from.value && this.defaultCustomFilters.to.value) {
        this.onCustomDateRangeFilter(
          this.defaultCustomFilters.from.value,
          this.defaultCustomFilters.to.value
        );
      }
    },

    onFromDateSelect(date) {
      this.defaultCustomFilters.from.value = date;
      if (this.defaultCustomFilters.to.value) {
        this.onCustomDateRangeFilter(date, this.defaultCustomFilters.to.value);
      }
    },
    onToDateSelect(date) {
      this.defaultCustomFilters.to.value = date;
      if (this.defaultCustomFilters.from.value) {
        this.onCustomDateRangeFilter(this.defaultCustomFilters.from.value, date);
      }
    },

    onCalendarMonthChange(e, data) {
      this.monthChangeClicked = true;
    },

    resetPreventHide() {
      this.$nextTick(() => {
        this.monthChangeClicked = false;
      });
    },

    /**
     *
     */
    async onProjectSearch(rawQuery) {
      const query = rawQuery.toLowerCase();
      this.projectsFilter.filters.customerLastname.value = query;
      await this.loadProjectData();
    },

    /**
     * only enable email button if customer has the email field filled
     */
    emailButtonDisabled(appointment) {
      return !(appointment.project && appointment.project.customer.email) ? true : false;
    },

    /**
     *
     */
    async onFilterEmployees(query) {
      console.log(query);
      this.filteredEmployees = [];
      this.getEmployees.map((employee) => {
        if (
          (employee.firstname && employee.firstname.toLowerCase().indexOf(query) >= 0) ||
          (employee.lastname && employee.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          console.log(employee);
          this.filteredEmployees.push(employee);
        }
      });
    },

    async onSelectProject(row) {
      const { data } = row;
      this.save(data);
    },

    async onSelectEmployee(row) {
      console.log(row);
      const { data } = row;
      this.save(data);
    },

    /**
     * send appointment data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;
      // console.log('onCellEditComplete():', event);
      // console.log(data, newValue, field);

      if (field === 'installationStartAt') {
        // update date grouping (day of week)
        data.weekday = this.$dayjs(newValue)
          .startOf('day')
          .format('dddd DD.MM.YYYY');

        // set installationEndAt to installationStartAt + 4 hour
        data.installationEndAt = this.$dayjs(newValue)
          .add(8, 'hour')
          .toDate();
      }

      data[field] = newValue;

      this.save(data);
    },

    async save(appointment) {
      if (appointment.number === '_new') {
        await this.createAppointment(appointment);
      } else {
        await this.updateAppointment(appointment);
      }
    },

    onCreateAppointment() {
      this.initAppointment();
      // this.expandAll();
    },

    /**
     * Copy the appointment data to a new appointment
     * Set the start date selected in the ConfirmButton Popup
     * @param {*} appointment
     */
    async onDuplicateAppointment(appointment) {
      const newAppointment = await this.duplicateAppointment({
        appointment: appointment,
        installationStartAt: this.copyDate,
      });
      // this.expandAll();
      this.save(newAppointment);
      appointment.appointmentState = 'ALTERNATIVE_DATE';
      this.save(appointment);
    },

    onDeleteAppointment(appointmentNumber) {
      // console.log('onDeleteAppointment():', appointmentNumber);
      this.deleteAppointment(appointmentNumber);
    },

    /**
     * send confirmation mail
     */
    async onSendAppointmentConfirmationMail(appointment) {
      await this.sendAppointmentMail({
        appointmentNumber: appointment.number,
      });
    },

    expandAll() {
      this.expandedRowGroups = this.getAppointments.map((a) => a.weekday);
    },
    collapseAll() {
      this.expandedRowGroups = null;
    },

    /**
     *
     */
    async clearDate() {
      this.calendarWeek = [];
      this.defaultCustomFilters.from.value = null;
      this.defaultCustomFilters.to.value = null;
      this.tableState.customFilters.from.value = null;
      this.tableState.customFilters.to.value = null;
      await this.loadAppointmentData();
    },

    /**
     * make new table entries identifiable by background color
     */
    rowClass(data) {
      return data.number === '_new' ? 'new' : null;
    },

    /**
     * Load remote table data
     */
    async loadAppointmentData() {
      await this.fetchAppointments({
        filters: { ...this.tableState.filters, ...this.tableState.customFilters },
      });
    },

    async loadProjectData() {
      if (this.isClient) {
        await this.fetchProjectsPaginatedByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      } else if (this.isAdmin) {
        await this.fetchProjectsPaginated({
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      }
      this.filteredProjects = this.getProjects;
    },

    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      // console.log('onFilter():', event);
      // this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentApprovedStateFilter(value) {
      if (this.tableState.filters.appointmentApprovedState) {
        this.tableState.filters.appointmentApprovedState.value = value;
      }

      await this.loadAppointmentData();
    },

    /**
     * Trigger when state is loaded from local storage
     */
    async onStateRestore(event) {
      // console.log('onStateRestore():', this.tableState);

      const customFiltersFromStorage = JSON.parse(
        localStorage.getItem(this.tableState.customFilterName)
      );
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;

      this.setupFilters();
    },

    /**
     * When selecting a single day, add 13 days to the date filter to represent two whole weeks.
     * Then close the calendar overlay.
     * @param {*} value
     */
    async onCalendarWeekFilter(value) {
      this.calendarWeek[1] = this.$dayjs(value)
        .add(6, 'days')
        .toDate();
      this.tableState.customFilters.from.value = this.calendarWeek[0];
      this.tableState.customFilters.to.value = this.calendarWeek[1];
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );
      this.$refs.appointmentCalendar.overlayVisible = false;
      await this.loadAppointmentData();
    },

    /**
     * Filtert appointments mit einer range und laedt die daten
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @async
     * @returns {Promise<void>}
     */

    async onCustomDateRangeFilter(startDate, endDate) {
      this.defaultCustomFilters.from.value = this.$dayjs(startDate).toDate();

      // Add one day to the end date for filtering
      const endDateInclusive = this.$dayjs(endDate)
        .add(1, 'day')
        .toDate();

      this.tableState.customFilters.from.value = this.defaultCustomFilters.from.value;
      this.tableState.customFilters.to.value = endDateInclusive;

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      this.$refs.appointmentCalendar.overlayVisible = false;
      await this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentStateFilter(value) {
      this.tableState.customFilters.appointmentStates = this.appointmentStates.filter(
        (appointmentState) => value.includes(appointmentState)
      );
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentTypeFilter(value) {
      this.tableState.customFilters.appointmentTypes = this.appointmentTypes.filter(
        (appointmentType) => value.includes(appointmentType)
      );
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    async onRemoveFilters(event) {
      this.clearDate();
      this.tableState.filters = this.defaultFilters;
      this.tableState.customFilters = this.defaultCustomFilters;

      this.setupFilters();
      localStorage.removeItem(this.tableState.filterName);
      localStorage.removeItem(this.tableState.customfilterName);
      await this.loadAppointmentData();
    },

    setupFilters() {
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;

      this.calendarWeek = [];
      if (this.tableState.customFilters.from.value && this.tableState.customFilters.to.value) {
        this.calendarWeek[0] = this.$dayjs(this.tableState.customFilters.from.value).toDate();
        this.calendarWeek[1] = this.$dayjs(this.tableState.customFilters.to.value).toDate();
      }
    },

    getAppointmentTypeColor,
    getAppointmentStateColor,
    getYesNoStateColor,
    yesNo,
  },
  async created() {
    this.setupFilters();
  },
  async mounted() {
    await this.fetchEnumValues('AppointmentType');
    await this.fetchEnumValues('AppointmentState');
    await this.fetchEmployees({
      page: 0,
      pageSize: 100,
      sortField: 'lastname',
      sortOrder: 1,
      filters: {},
    });
    this.filteredEmployees = this.getEmployees;
    await this.loadAppointmentData();
    await this.loadProjectData();
    await this.onRemoveFilters();
  },
};
</script>

<style scoped lang="scss">
::v-deep .center .p-column-header-content {
  justify-content: center;
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}

::v-deep tr.p-rowgroup-header {
  background: $gray-100;
  span {
    font-weight: 700;
  }
  .p-row-toggler {
    vertical-align: middle;
    margin-right: 0.25rem;
  }
}

.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.multiselect-employees {
  min-width: 230px;
  display: inline-block;
}

::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

::v-deep .multiselect-employees .multiselect__input {
  padding-top: 1em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}

::v-deep .p-datepicker-title {
  display: flex;
}

::v-deep .btn-icon > button {
  padding: 0;
}

.btn-xs {
  border-radius: 0;
}

::v-deep .p-column-filter-clear-button {
  display: none;
}
::v-deep .p-column-filter-menu-button {
  display: none;
}
</style>
